<template>
	<MobileSideMenu></MobileSideMenu>
	<div class="inplay-details-sec" v-if="checkIsLogin() && this.$route.name != 'Home'">
		<div class="row g-1">
			<div class="col-0 col-sm-0 col-md-0 col-lg-2">
				<SideMenu @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></SideMenu>
			</div>
			<div class="col-12 col-sm-12 col-md-12 col-lg-10">
				<div class="main-page-details">

					<MainHeader @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></MainHeader>

					<!-- //inplay-page-start here/ -->
					<transition name="fade">
						<div class="preloader d-flex align-items-center justify-content-center" v-if="getGlobaLoader()">
							<div class="cssload-container">
								<div class="cssload-loading"><i></i><i></i><i></i><i></i></div>
							</div>
						</div>
					</transition>
					<router-view @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></router-view>

					<!-- //end here// -->
				</div>
			</div>
		</div>
		<MobileFooter @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></MobileFooter>
		<div data-bs-toggle="modal" data-bs-target="#notification-alert" ref="notifyMe">
		</div>
		<NotificationAlert @allowNotifications="allowNotifications" @cancelNotifications="cancelNotifications"/>	
		<GamesPop1 @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
		<GamesPop2 @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />

		<LanguageSelection @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />

		<Account @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" @updateAuthToken="refreshAuthToken" />
	</div>

	<router-view v-if="this.$route.name == 'Home'" @error-modal="showErrorModalFunc"
		@success-modal="showSuccessModalFunc"></router-view>
	<div :class="{ show: showSuccessModal }" class="successfully-wrapper" style="z-index: 9999999999999999;"
		ref="successmodal" id="successfullyModal">
		<SuccessModal v-if="showSuccessModal" :message="successMsg" />
	</div>
	<div :class="{ show: showErrorModal }" style="z-index: 9999999999999999999999;"
		class="successfully-wrapper successfullytoaster-sec" id="wrongtoaster">
		<ErrorModal v-if="showErrorModal" :message="errorMsg" />
	</div>
</template>

<script>
import NotificationAlert from "./shared/components/modal/NotificationAlert.vue";
import MobileFooter from './shared/components/footer/MobileFooter.vue';
import MainHeader from './shared/components/header/MainHeader.vue';
import MobileSideMenu from './shared/components/header/MobileSideMenu.vue';
import SideMenu from './shared/components/header/SideMenu.vue';
import { setHeaders } from '@/shared/services/headers';
import { AUTH_TOKEN } from "./shared/constants/cookies-const";
import axios from 'axios';
import api from '@/shared/services/api';
import * as apiName from '@/shared/services/urls';

import GamesPop1 from "./shared/components/modal/GamesPop1.vue";
import GamesPop2 from "./shared/components/modal/GamesPop2.vue";
import { callFavouriteApis } from '@/shared/services/common-services';
import SuccessModal from './shared/components/modal/SuccessModal.vue';
import ErrorModal from './shared/components/modal/ErrorModal.vue';
import LanguageSelection from './shared/components/header/LanguageSelection.vue';

import moment from 'moment';
import Account from './modules/account/components/Account.vue';
import socketService from "./socket/sport-socket-service";
import { provide ,computed } from 'vue'
import LanguageService from '@/shared/services/language-service.js';

export default {
	name: 'App',
	components: {
		MainHeader,
		MobileSideMenu,
		SideMenu,
		MobileFooter,
		GamesPop1,
		GamesPop2,
		SuccessModal,
		ErrorModal,
		LanguageSelection,
		NotificationAlert,
		Account
	},

	data() {
		return {
			onLine: navigator.onLine,
			showBackOnline: false,
			loading: false,
			showSuccessModal: false,
			successMsg: "",
			showErrorModal: false,
			errorMsg: "",
			isLoading: true,
			refreshInit: false,
			langData: null,
			findActiveLang:"",
			allLanguages:null,
		}
	},
	
	provide() {
		return {
			translatedLangData: computed(() => this.translatedData)
		};
	},
	mounted() {
		document.body.classList.add('inplay-sec');
		window.store = this.$store;
		window.cookies = this.$cookies;
		window.router = this.$router;
		window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
		axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
		setHeaders();



		if (this.checkIsLogin()) {
			callFavouriteApis();
			if(this.$store.getters.stateUser.notificationUnsubscribed==1){
				return;
			}
			if(this.$store.getters.stateUser.notificationSubscribed!=1) {
				this.requestNotificationPermission()
			}
		}
		//checking internet connection
		window.addEventListener('online', this.updateOnlineStatus);
		window.addEventListener('offline', this.updateOnlineStatus);
		if (localStorage.getItem('default_class')) {
			document.documentElement.className = localStorage.getItem('default_class')
		}
	},
	async created() {
		this.socketAllEvents()
	 await this.getSiteSettingsServiceCall();
		
	},
	beforeDestroy() {
		window.removeEventListener('online', this.updateOnlineStatus);
		window.removeEventListener('offline', this.updateOnlineStatus);
	},

	methods: {
		requestNotificationPermission() {
			if (!('Notification' in window)) {
				console.log('This browser does not support notifications.');
				return;
			}

			if (Notification.permission === 'granted') {				
				this.getSubscriptionData();
			} else if (Notification.permission !== 'denied') {
				this.openNotificationBox()
			}
		},
		openNotificationBox(){
			this.$refs.notifyMe.click()
		},
		allowNotifications(){
			Notification.requestPermission().then(permission => {
				if (permission === 'granted') {
					console.log('Notification permission granted.');
					this.getSubscriptionData();
				}
			});
		},
		cancelNotifications(){
			let user=this.$store.getters.stateUser;
			user.notificationUnsubscribed=1;
			this.$store.dispatch('setUser', user);
		},
		async getSubscriptionData(){
			let user=this.$store.getters.stateUser;
			user.notificationSubscribed=1;
			this.$store.dispatch('setUser', user);
			if ('serviceWorker' in navigator) {
				try {
					await navigator.serviceWorker.register('/service-worker.js').then(async registration => {

						setTimeout(async () => {
							if ('PushManager' in window) {
								if (registration.pushManager) {
									// Subscribe to push notifications
									const subscription = await registration.pushManager.subscribe({
										userVisibleOnly: true,
										applicationServerKey: process.env.VUE_APP_PUBLIC_VAPID_KEY
									});
									this.deviceData=subscription
									this.subscribeNotifications()
								} else {
									console.warn('PushManager is not available');
								}
							} else {
								console.warn('Push notifications are not supported');
							}
						}, 1000)

					}).catch(e => {
						console.log(e);
					});

					
				
				} catch (error) {
					console.error('Service Worker registration failed:', error);
				}
			} else {
				console.warn('Service workers are not supported in this browser.');
			}
		},
		subscribeNotifications(){
			let authToken = this.$cookies.get(AUTH_TOKEN);
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
			let data = {
					"deviceData": this.deviceData,
					"domainName": window.location.host,
					};
				api.post(apiName.SUBSCRIBE_NOTIFICATION, data, { headers }).then(response => {
					this.loadingSignUp = false;
					if (response) {
						console.log(response)
					}
				}).catch(error => {
					this.loadingSignUp = false;
					if (error)
						this.showErrorModalFunc(error[0]);
				});
		},
		translatedData(key, defaultVal) {
			if(this.langData?.[key]) {
				return this.langData?.[key];
			} else {
				return defaultVal;
			}
		},
		getGlobaLoader() {
			return this.$store.getters.globalLoader;
		},
		async getSiteSettingsServiceCall() {
			this.loading = true;
			this.$store.dispatch('setGlobalLoader', true);
			await api.get(apiName.SITE_SETTINGS + "?domain=" + window.location.hostname).then(async response => {
				this.loading = false;
				this.$store.dispatch('setGlobalLoader', false);
				if (response && response.status == 200) {
					if (response?.data?.status === 0) {
						this.showErrorModalFunc(response.data.debug[0]);
					} else {
						let resData = response?.data?.data
						this.allLanguages = resData.language_translate_list;
						this.$store.dispatch('setLanguagesData', this.allLanguages);
						if (this.allLanguages) {
							this.findActiveLang =this.allLanguages?.find(lang => lang?.lang_code === resData?.language_code);
								const selectedLanguageUrl =  localStorage.getItem('selectedLanguageUrl');
								if ((this.findActiveLang && this.findActiveLang?.lang_url) || selectedLanguageUrl ) {
									const langCode = selectedLanguageUrl || this.findActiveLang?.lang_url;
									const aws_url_language = resData?.aws_url+ '/' +langCode
									this.langData = await LanguageService.getLanguageTranslationKey(aws_url_language)
								}
						}

						this.$store.dispatch('setSettings', resData);
						this.setManifest(resData);

						const favElement = document.createElement('link');
						favElement.rel = 'icon';
						favElement.href = resData.aws_url + resData.storage_path.domain_image + resData.fav_logo;
						document.head.insertAdjacentElement('beforeend', favElement);

						const title = document.getElementById("title");
              			title.text = resData?.domain_name;

						if (resData.front_css != null && resData.front_css != '') {// Create a style element
							const styleElement = document.createElement('style');
							styleElement.type = 'text/css';
							styleElement.appendChild(document.createTextNode(resData.front_css));
							document.head.insertAdjacentElement('beforeend', styleElement);
						}

						if (this.checkIsLogin())
							this.getWalletBalance();
					}
				}
			}).catch(error => {
				this.loading = false;
				if (error) {
					console.log("Error Login : ", error);
					this.showErrorModalFunc(error[0]);
				}
			});
		},
		setManifest(resData) {
			let manifestName = resData.domain_name.charAt(0).toUpperCase() + resData.domain_name.slice(1).toLowerCase();
			var dynamicManifest = {
				name: manifestName,
				short_name: manifestName,
				start_url: window.location.origin,
				display: 'standalone',
				icons: [{
					src: resData.app_logo ? resData.aws_url + resData.storage_path.domain_image + resData.app_logo : resData.aws_url + resData.storage_path.domain_image + resData.fav_logo,
					sizes: "256x256 512x512 192x192 144x144",
					type: "image/png",
					purpose: 'any'
				}]
			}

			// Manifest Blob
			const stringManifest = JSON.stringify(dynamicManifest);
			const blob = new Blob([stringManifest], { type: 'application/json' });
			const manifestURL = URL.createObjectURL(blob);

			// Manifest Creation
			const favElement = document.createElement('link');
			favElement.rel = 'manifest';
			favElement.href = manifestURL;
			document.head.insertAdjacentElement('beforeend', favElement);
			
			// IOS APK Logo
			let logo = resData.aws_url + resData.storage_path.domain_image + resData.logo;
			document.querySelector('#appleIcon').setAttribute('href', logo);
			document.querySelector('#appleLaunchIcon').setAttribute('href', logo);

			// IOS App title
			const metaTag = document.createElement('meta');
			metaTag.setAttribute('name', 'apple-mobile-web-app-title');
			metaTag.setAttribute('content', manifestName);
			document.head.insertAdjacentElement('beforeend',metaTag);
		},
		getWalletBalance() {
			this.loading = true;
			let authToken = this.$cookies.get(AUTH_TOKEN);
			if (!authToken) return;
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
			api.post(apiName.WALLET_BALANCE, {}, { headers }).then(response => {
				this.loading = false;
				if (response && response.status == 200 && response.data) {
					let user = this.$store.getters.stateUser;
					user.balance = response.data.bl;
					user.exposure = response.data.ex;
					user.bets_count = response.data.bc;
					this.$store.dispatch('setUser', user);
					if ((moment.unix(user.exp).diff(moment(), 'seconds') / 60) < 5 && !this.refreshInit) {
						this.refreshAuthToken(headers);
					}
					setTimeout(function () {
						this.getWalletBalance();
					}.bind(this), 2000);
				} else {
					this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
					this.$store.dispatch('resetAllState');
				}
			}).catch(error => {
				this.loading = false;
				if (error) {
					console.log("Error Login : ", error[0]);
					this.showErrorModalFunc(error[0]);
				}
			});
		},
		refreshAuthToken(refresh = false) {
			this.refreshInit = true;
			let authToken = this.$cookies.get(AUTH_TOKEN);
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
			api.post(apiName.AUTH_REFRESH, {}, { headers }).then(response => {
				let user = this.$store.getters.stateUser;
				user.token = response.data.data.token;
				user.exp = response.data.data.exp;
				this.$cookies.set(AUTH_TOKEN, response.data.data.token);
				this.$store.dispatch('setToken', `Bearer ${response.data.data.token}`);
				window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
				//for add on all APIs
				axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
				this.$store.dispatch('setUser', user);
				this.refreshInit = false;
				if(refresh) {
					router.go();
				}
			}).catch(error => {
				if (error) {
					console.log("Error Login : ", error[0]);
					this.showErrorModalFunc(error[0]);
				}
				this.refreshInit = false;
			});
		},
		checkForHeaderShow() {
			let routeName = this.$route.name;
			if (routeName == 'search') {
				return false;
			} else {
				return true;
			}
		},
		updateOnlineStatus(e) {
			const {
				type
			} = e;
			this.onLine = type === 'online';
		},

		checkIsLogin() {
			return this.$store.getters.isAuthenticated;
		},

		showErrorModalFunc(message) {
			this.errorMsg = message;
			this.showErrorModal = true;
			setTimeout(() => {
				this.showErrorModal = false;
			}, 2000);
			//window.scrollTo(0, 0);
		},
		showSuccessModalFunc(message) {
			this.successMsg = message;
			this.showSuccessModal = true;
			setTimeout(() => {
				this.showSuccessModal = false;
			}, 2000);
			//window.scrollTo(0, 0);
		},
		socketAllEvents() {
			if (this.$store.getters.stateUser) {
				socketService.emit("sub", 'depositRequest-' + this.$store.getters.stateUser.userid);
				socketService.emit("sub", 'logout-' + this.$store.getters.stateUser.userid);
				socketService.emit("sub", 'banned-' + this.$store.getters.stateUser.userid);

				socketService.on("DepositRequest", (data) => {
					if (data.type == "myId") {
						if (data.notify_type == 1) {
							this.$store.dispatch('setNotification');
							this.showSuccessModalFunc('New Notification...')
						}
					}
				});
				socketService.on("logout", (data) => {
					if (data.type == "logout") {
						// logout user from users
						// if (data.notify_type == 1) {
						// 	this.$store.dispatch('setNotification');
						// 	this.showSuccessModalFunc('New Notification...')
						// }
					}
				});
				socketService.on("banned", (data) => {
					if (data.type == "match_ban") {
						// match ban condition
					} else if (data.type == 'league_ban') {
						// league ban condition
					}
				});

			}
		},
	},

	watch: {
		onLine(v) {
			if (v) {
				this.showBackOnline = true;
				setTimeout(() => {
					this.showBackOnline = false;
				}, 1000);
			}
		}
	}

}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 1.5s
}

.fade-enter,
.fade-leave-to {
	opacity: 0
}
</style>
